/* blocks */
/* blocks */
.ck-editor__editable_inline {
	min-height: 500px !important;
}
.slide-preview {
	padding: 10px 15px !important;
	max-width: 100% !important;
}
.slide-preview img {
	max-width: 100%;
}
.slide-preview table {
	max-width: 100%;
}
.slide-preview figure[class='table'] {
	width: 100% !important;
	max-width: 100% !important;
}

/* blocks */
/* blocks */

/* locator */
/* locator */
span.badge {
	margin-left: 5px;
	font-size: 15px;
	position: relative;
	bottom: 2px;
}
span.badge-course {
	background-color: var(--se-dia-naranja) !important;
	color: #fff;
}
span.badge-module {
	background-color: var(--se-dia-azulclaro) !important;
	color: #333;
	font-size: 12px;
}
span.badge-lesson {
	background-color: var(--se-dia-verde) !important;
	color: #fff;
	font-size: 12px;
}
/* locator */
/* locator */

.lms-btn-back {
	background-color: var(--se-dia-morado) !important;
	border-color: var(--se-dia-morado) !important;
	color: #333;
}
.lms-btn-back:hover {
	color: #fff;
}
.blockBadge {
	position: absolute !important;
	z-index: 200;
	top: 10px;
	right: 10px;
	background-color: #e8e8e8;
	color: #999;
	bottom: auto !important;
}
.player-wrapper {
	position: relative;
	padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}