.course-title {
	text-transform: uppercase;
}
.course-title span {
	position: relative;
	bottom: 2px;
	background-color: var(--se-dia-morado) !important;
	color: #333;
}
.module-table .ellipsis {
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.module-list-actions .module-btn {
	margin-right: 5px;
	margin-bottom: 2px;
}
.module-list-actions .module-btn-manage {
	background-color: var(--se-dia-azul);
	border-color: var(--se-dia-azul);
	color: #FFF;
}
.module-list-actions .module-btn-manage:hover {
	color: #999;
}
.module-list-actions .module-btn-edit {
	background-color: var(--se-dia-verde);
	border-color: var(--se-dia-verde);
}
.module-list-actions .module-btn-edit:hover {
	color: #333;
}

.module-container-form {
	padding:15px;
}

.module-btn-cancel {
	background-color: var(--se-dia-amarillo);
	border-color: var(--se-dia-amarillo);
	margin-left: 5px;
}
.module-btn-cancel:hover {
	background-color: var(--se-dia-amarillo);
	border-color: var(--se-dia-amarillo);
	color:#999;
}