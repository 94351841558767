:root {
	--se-bg: #283842;
	--se-bg-light: #f3f5f8;
	--se-dia-rosa: #e9afb7;
	--se-dia-naranja: #cb843f;
	--se-dia-amarillo: #f5e5bb;
	--se-dia-verde: #87c053;
	--se-dia-azulclaro: #b7dddb;
	--se-dia-azul: #1b5292;
	--se-dia-morado: #ccbcd4;

	--se-texto-base: #fff;
	--se-texto-hover: #838383;
}
html {
	position: relative;
	min-height: 100%;
}
body {
	margin: 0 0 120px;
	background-color: var(--se-bg-light);
	/* bottom = footer height */
	font-size: 15px;
}
a {
	text-decoration: none;
	color: inherit;
}
a:hover {
	color: var(--se-dia-azul);
}
.se-container {
	padding: 0px;
}

/* text */
/* text */
.text-hint {
	color: #999;
}
/* text */
/* text */

/* forms */
/* forms */
.se-form label {
	font-size: 14px;
}
.se-form input,
.se-form select {
	margin-bottom: 10px;
	font-size: 14px;
}
.se-form input[type='text'] {
	font-size: 14px;
}
/* forms */
/* forms */

/* pages */
/* pages */
.se-container-page {
	padding-top: 50px;
}
/* pages */
/* pages */

/* navBar */
/* navBar */
.navbar-brand img {
	width: 80px;
	height: auto;
}
.navbar-se {
	background-color: var(--se-bg);
}
.navbar-se .navbar-nav .nav-link {
	color: var(--se-texto-base);
}
.navbar-se .navbar-nav .nav-link:hover {
	color: var(--se-texto-hover);
}
.navbar-se .navbar-text {
	color: var(--se-texto-base);
}
.navbar-se .navbar-text a {
	color: var(--se-texto-base);
}
.navbar-se .navbar-text a:hover {
	color: var(--se-texto-hover);
}
.navbar-se .navbar-nav .nav-item .show {
	color: var(--se-texto-hover);
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
	color: var(--se-texto-hover);
}
.navbar-toggler {
	border-color: #fff !important;
}
.se-avatar {
	border-radius: 50%;
	height: 30px;
}
.se-icon-logout {
	position: relative;
	bottom: 2px;
	margin-right: 5px;
}
/* navBar */
/* navBar */

/* hero */
/* hero */
.se-hero {
	background-color: #fff;
}
.se-hero h3 {
	font-size: 19px;
	text-transform: uppercase;
}
.se-img-hero {
	width: 300px;
	height: auto;
	margin-bottom: 20px;
}
.se-hero-rayitas {
	width: 100%;
	height: 9px;
	background-repeat: no-repeat;
	background-image: url('img/rayitas@2x.png');
	background-size: 100% 100%;
	background-position: top 100% center 100% !important;
	box-sizing: border-box;
}
/* hero */
/* hero */

/* footer */
/* footer */
footer {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 120px;
	width: 100%;
	overflow: hidden;
}
.se-footer {
	border-top: 2px solid #979797;
	box-sizing: border-box !important;
	background-color: #fff;
}
.se-img-footer {
	width: 130px;
	height: auto;
	margin-bottom: 20px;
}
.se-footer h4 {
	font-size: 13px;
	/* text-transform: uppercase; */
	font-weight: 200;
}
/* footer */
/* footer */

/* sections */
/* sections */
.se-section {
	padding: 20px;
}
/* sections */
/* sections */

/* responsive */
/* responsive */
@media screen and (max-width: 450px) {
	.se-img-hero {
		width: 150px;
	}
	.se-hero h3 {
		font-size: 15px;
	}
	.se-footer h4 {
		font-size: 11px;
	}
}
/* responsive */
/* responsive */

.se-user-photo {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background-color: gray;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	background-attachment: cover;
	background-position: 50% 50%;
	background-size: 200px auto;
	background-repeat: no-repeat;
}

.se-user-loading-row {
	margin-top: 50px;
	padding: 50px;
	text-align: center;
}
.se-user-loading-spinner {
	margin-top: 50px;
	padding-bottom: 50px;
}

