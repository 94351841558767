.se-container {
	padding-top: 0px;
}
.courseList-card {
	padding:20px;
}
.courseList-actions button {
	margin-right: 5px;
	margin-bottom: 5px;
}
.course-image {
	width:100%;
	min-height: 250px;
	background-color: #e8e8e8;
	color: #666;
	display: flex;
	align-items: center;
	justify-content: center;
	background-attachment: cover;
	background-position: 50% 50%;
	background-size: 100% auto;
	background-repeat: no-repeat;
}
.course-image p {
	/* padding-top: 25%; */
}
.course-date-picker {
	width:100%;
	padding:8px 10px;
	border: 1px solid #ced4da;
	border-radius: 5px;
}
.with-error {
	border-color: red;
}
.course-btn-add {
	background-color: var(--se-dia-verde) !important;
	border-color: var(--se-dia-verde) !important;
	color:#333;
}
.courseList-actions .course-btn-manage {
	background-color: var(--se-dia-verde) !important;
	border-color: var(--se-dia-verde) !important;
	color:#333;
}
.courseList-actions .course-btn-manage:hover {
	color:#FFF;
}
.courseList-actions .course-btn-edit {
	background-color: var(--se-dia-azulclaro) !important;
	border-color: var(--se-dia-azulclaro) !important;
	color:#333;
}
.courseList-actions .course-btn-edit:hover {
	color:#FFF;
}
.courseList-actions .course-btn-delete {
	background-color: var(--se-dia-naranja) !important;
	border-color: var(--se-dia-naranja) !important;
	color:#333;
}
.courseList-actions .course-btn-delete:hover {
	color:#FFF;
}